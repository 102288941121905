import React from 'react';
//import so1 from "../../assets/images/so1.png";
//import so2 from "../../assets/images/so2.png";

const Solutions = (props) => (
    <section className="sec-solutions">
        <div className="container my-5">
            <div className="col-md-10 mx-auto">
                <div class="row">
                    <div class="col-md-2"></div>
                    {props.data&&
                     props.data.allWpCaseStudy &&
                     props.data.allWpCaseStudy.edges[0] &&
                     props.data.allWpCaseStudy.edges[0].node &&
                     props.data.allWpCaseStudy.edges[0].node.CaseStudyFields &&
                     props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.solution &&
                     <div class="col-md-10">
                        <div class="share-sub-heading">{props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.solution.subTitle}</div>
                        <h2 class="share-main-title w-100">
                            {
                               props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.solution.title
                            }
                        </h2>
                        <div dangerouslySetInnerHTML={{__html:props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.solution.description}}></div>
                       
                        <div class="row mb-5 d-flex case-figures align-items-center px-0">
                            <span class="col-md-6 px-0"> 
                               <img src={props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.solution.image1.sourceUrl} alt="" />
                            </span>
                            <span class="col-md-6 px-0"> 
                                <img src={props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.solution.image2.sourceUrl} alt="" />
                            </span>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    </section>
)

export default Solutions;