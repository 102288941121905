import React from 'react';
import singlecbg from '../../assets/images/single-c-bg.png'


const Banner = (props) => {

    return(
        <>
        <div>
        {props.data && 
        props.data.allWpCaseStudy &&
        props.data.allWpCaseStudy.edges[0] &&
        props.data.allWpCaseStudy.edges[0].node &&
        <section className=" single-case-banner">
            <div className="bn-bg">
               <img src={singlecbg} alt="case study" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6  d-flex flex-column sectiontop-pd ">
                        <div className="banner-text case-heading my-auto ml-0 ">
                            <h3>{props.data.allWpCaseStudy.edges[0].node.title}</h3>
                            <p dangerouslySetInnerHTML={{__html:props.data.allWpCaseStudy.edges[0].node.content}}></p>
                            <h4 className="mb-1 serv-tl">{props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.services.title}</h4>
                            <div className="d-flex mb-3 banner-btns">
                              {props.data.allWpCaseStudy.edges[0].node.CaseStudyFields
                              &&props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.services 
                              &&props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.services.servicesList
                              &&props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.services.servicesList.length>0
                              &&props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.services.servicesList.map((service, key)=>{
                                   return(
                                      <button key={key} className={`ui ${key===0?'ml-0':""}`}>{service.name}</button> 
                                   )
                              })}
                            </div>
                            <a className="site-link" href={props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.services.websiteLink}>{props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.services.visitWebsite}</a>
                        </div>
                    </div>
                    <div className="col-md-6 py-5 case-image">
                        <div className="d-flex mobile-hidden py-5 justify-content-center align-items-center  sectiontop-pd sectionbtm-pd">
                            <img src={props.data.allWpCaseStudy.edges[0].node.featuredImage.node.sourceUrl} alt="case study" />
                        </div>
                    </div>
                </div>
            </div>
          </section>} 
         </div>
         </> 
    )
}
    
export default Banner;