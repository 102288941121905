import React from 'react';
import chlg from "../../assets/images/chlg.png"; 

const Challenges = (props) => (
    <section className="chalanges-sec">
        <div className="chlange-bg">
            <img src={chlg} alt="case study" />
        </div>
        <div className="container my-5 case-padding">
            <div className="col-md-10 mx-auto">
                <div className="row">
                  <div className="col-lg-2"></div>
                  {props.data&&
                     props.data.allWpCaseStudy &&
                     props.data.allWpCaseStudy.edges[0] &&
                     props.data.allWpCaseStudy.edges[0].node &&
                     props.data.allWpCaseStudy.edges[0].node.CaseStudyFields &&
                     props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.notableChallanges &&
                  <div className="col-lg-10">
                    <div className="chlange-sub-heading ">{props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.notableChallanges.subTitle}</div>
                    <h2 className="chlange-title w-100 text-light">{props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.notableChallanges.title}</h2>
                    <div className="text-light" dangerouslySetInnerHTML={{__html:props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.notableChallanges.description}}></div>
                </div>}
              </div>  
            </div>
        </div>
    </section> 
)

export default Challenges;