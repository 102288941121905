import React from 'react';
import c1 from "../../assets/images/c1.png"; 
import c2 from "../../assets/images/c2.png";
import tx from "../../assets/images/tx.png";

const SimilarCaseStudy = (props) => {
   console.log("Data", props.data);
   return(
     <section className="web-sec">
      <div className="container">
       <div className="row mt-5 mb-3">
          <div className="case-heading d-flex align-items-center w-100">
             <h1 className="mb-0">Similar case studies</h1>
          </div>
       </div>
       <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-6">
             <div className="case-items mb-4">
                <div className="pro-img mb-3">
                   <img src={c1} alt="" />
                   <div className="views d-flex justify-content-end">
                      <span>
                         <h6>100k+</h6>
                         <p>Downloads</p>
                      </span>
                      <span class="up-one">
                         <h6>4.4/5</h6>
                         <p>Avg. Rating</p>
                      </span>
                      <span class="up-two">
                         <h6>$1.1M</h6>
                         <p>Funding<br /> Raised</p>
                      </span>
                   </div>
                </div>
                <div className="px-4 case-content-outer">
                   <div className="d-flex justify-content-between align-items-center py-3 ">
                      <div className="care-title d-flex align-items-center">
                         <span>  
                             <img src={tx} alt="video" />
                         </span>
                         <h4 className="mb-0">Tixio</h4>
                      </div>
                      <div className="store d-flex  align-items-center ">
                         <p className="mb-0">Available on:</p>
                         <span className="mx-3">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M11.2286 2.54644C11.4807 1.89971 11.9216 1.34378 12.4939 0.950947C13.0661 0.558112 13.7434 0.346544 14.4375 0.34375C14.6198 0.34375 14.7947 0.416183 14.9236 0.545114C15.0526 0.674045 15.125 0.848914 15.125 1.03125C15.125 1.21359 15.0526 1.38845 14.9236 1.51739C14.7947 1.64632 14.6198 1.71875 14.4375 1.71875C14.021 1.72041 13.6145 1.84735 13.2711 2.08308C12.9277 2.3188 12.6631 2.6524 12.5118 3.0405C12.4462 3.21049 12.3157 3.34746 12.1491 3.42133C11.9826 3.4952 11.7935 3.49991 11.6234 3.43444C11.4534 3.36896 11.3163 3.23866 11.2422 3.07215C11.1682 2.90565 11.1633 2.71656 11.2286 2.54645V2.54644ZM19.082 13.3853C18.5328 13.1092 18.0677 12.691 17.7349 12.1742C17.4022 11.6574 17.2141 11.0609 17.1901 10.4467C17.1662 9.83254 17.3072 9.22316 17.5987 8.682C17.8901 8.14085 18.3213 7.68769 18.8473 7.36969C18.9305 7.31924 19.0021 7.25168 19.0574 7.17148C19.1126 7.09128 19.1501 7.00027 19.1675 6.90447C19.1849 6.80867 19.1817 6.71027 19.1583 6.61577C19.1348 6.52128 19.0915 6.43286 19.0313 6.35636C18.0965 5.16801 16.7377 4.38809 15.2402 4.18041C13.7426 3.97272 12.2228 4.35343 11 5.24257C10.1282 4.60859 9.09798 4.2281 8.02337 4.14317C6.94875 4.05824 5.87158 4.27218 4.91099 4.76133C3.9504 5.25048 3.14381 5.99579 2.58043 6.91481C2.01705 7.83384 1.71883 8.89078 1.71875 9.96875C1.71875 12.1604 2.43512 14.4776 3.68423 16.3263C4.92122 18.1571 6.49953 19.25 7.90625 19.25H14.0938C16.0244 19.25 18.2124 17.1991 19.4147 14.2626C19.4806 14.1014 19.4829 13.9212 19.4211 13.7584C19.3594 13.5956 19.2382 13.4622 19.082 13.3853Z" fill="black"/>
                            </svg>
                         </span>
                         <span>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M19.2184 9.81243L4.79957 1.55899C4.58897 1.43685 4.34971 1.37285 4.10625 1.37354C3.8628 1.37423 3.6239 1.4396 3.414 1.56294C3.20672 1.68092 3.03452 1.85185 2.915 2.05824C2.79548 2.26463 2.73295 2.49907 2.7338 2.73757V19.2622C2.73295 19.5007 2.79548 19.7352 2.915 19.9416C3.03452 20.148 3.20672 20.3189 3.414 20.4369C3.6239 20.5602 3.8628 20.6256 4.10625 20.6263C4.34971 20.627 4.58898 20.563 4.79957 20.4408L19.2184 12.1874C19.4313 12.0706 19.6088 11.8988 19.7324 11.6899C19.8561 11.481 19.9213 11.2427 19.9213 10.9999C19.9213 10.7572 19.8561 10.5189 19.7324 10.31C19.6088 10.1011 19.4313 9.92923 19.2184 9.81244L19.2184 9.81243ZM12.3748 11.9722L13.9953 13.5926L6.41644 17.9305L12.3748 11.9722ZM6.41547 4.06826L13.9954 8.40709L12.3748 10.0277L6.41547 4.06826ZM15.232 12.8848L13.3471 10.9999L15.2321 9.11494L18.5251 10.9999L15.232 12.8848Z" fill="black"/>
                            </svg>
                         </span>
                      </div>
                   </div>
                   <p className="case-disp pb-3 ">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl ac donec nulla ut vel. Urna molestie..</p>
                   <button  onclick="location.href = 'single-case-study.html'; return false;" class="estimate-btn btn btn-bg btn-design text-white px-4 mb-4 ">
                   Read Case Study 
                   </button>
                </div>
             </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6">
             <div className="case-items mb-4">
                <div className="pro-img mb-3">
                   <img src={c2} alt="" />
                   <div className="views d-flex justify-content-end">
                      <span>
                         <h6>100k+</h6>
                         <p>Downloads</p>
                      </span>
                      <span className="up-one">
                         <h6>4.4/5</h6>
                         <p>Avg. Rating</p>
                      </span>
                      <span className="up-two">
                         <h6>$1.1M</h6>
                         <p>Funding<br /> Raised</p>
                      </span>
                   </div>
                </div>
                <div className="px-4 case-content-outer">
                   <div className="d-flex justify-content-between align-items-center py-3 ">
                      <div className="care-title d-flex align-items-center">
                         <span>  
                             <img src={tx} alt="video" />
                         </span>
                         <h4 className="mb-0">Tixio</h4>
                      </div>
                      <div className="store d-flex  align-items-center ">
                         <p className="mb-0">Available on:</p>
                         <span className="mx-3">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M11.2286 2.54644C11.4807 1.89971 11.9216 1.34378 12.4939 0.950947C13.0661 0.558112 13.7434 0.346544 14.4375 0.34375C14.6198 0.34375 14.7947 0.416183 14.9236 0.545114C15.0526 0.674045 15.125 0.848914 15.125 1.03125C15.125 1.21359 15.0526 1.38845 14.9236 1.51739C14.7947 1.64632 14.6198 1.71875 14.4375 1.71875C14.021 1.72041 13.6145 1.84735 13.2711 2.08308C12.9277 2.3188 12.6631 2.6524 12.5118 3.0405C12.4462 3.21049 12.3157 3.34746 12.1491 3.42133C11.9826 3.4952 11.7935 3.49991 11.6234 3.43444C11.4534 3.36896 11.3163 3.23866 11.2422 3.07215C11.1682 2.90565 11.1633 2.71656 11.2286 2.54645V2.54644ZM19.082 13.3853C18.5328 13.1092 18.0677 12.691 17.7349 12.1742C17.4022 11.6574 17.2141 11.0609 17.1901 10.4467C17.1662 9.83254 17.3072 9.22316 17.5987 8.682C17.8901 8.14085 18.3213 7.68769 18.8473 7.36969C18.9305 7.31924 19.0021 7.25168 19.0574 7.17148C19.1126 7.09128 19.1501 7.00027 19.1675 6.90447C19.1849 6.80867 19.1817 6.71027 19.1583 6.61577C19.1348 6.52128 19.0915 6.43286 19.0313 6.35636C18.0965 5.16801 16.7377 4.38809 15.2402 4.18041C13.7426 3.97272 12.2228 4.35343 11 5.24257C10.1282 4.60859 9.09798 4.2281 8.02337 4.14317C6.94875 4.05824 5.87158 4.27218 4.91099 4.76133C3.9504 5.25048 3.14381 5.99579 2.58043 6.91481C2.01705 7.83384 1.71883 8.89078 1.71875 9.96875C1.71875 12.1604 2.43512 14.4776 3.68423 16.3263C4.92122 18.1571 6.49953 19.25 7.90625 19.25H14.0938C16.0244 19.25 18.2124 17.1991 19.4147 14.2626C19.4806 14.1014 19.4829 13.9212 19.4211 13.7584C19.3594 13.5956 19.2382 13.4622 19.082 13.3853Z" fill="black"/>
                            </svg>
                         </span>
                         <span>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M19.2184 9.81243L4.79957 1.55899C4.58897 1.43685 4.34971 1.37285 4.10625 1.37354C3.8628 1.37423 3.6239 1.4396 3.414 1.56294C3.20672 1.68092 3.03452 1.85185 2.915 2.05824C2.79548 2.26463 2.73295 2.49907 2.7338 2.73757V19.2622C2.73295 19.5007 2.79548 19.7352 2.915 19.9416C3.03452 20.148 3.20672 20.3189 3.414 20.4369C3.6239 20.5602 3.8628 20.6256 4.10625 20.6263C4.34971 20.627 4.58898 20.563 4.79957 20.4408L19.2184 12.1874C19.4313 12.0706 19.6088 11.8988 19.7324 11.6899C19.8561 11.481 19.9213 11.2427 19.9213 10.9999C19.9213 10.7572 19.8561 10.5189 19.7324 10.31C19.6088 10.1011 19.4313 9.92923 19.2184 9.81244L19.2184 9.81243ZM12.3748 11.9722L13.9953 13.5926L6.41644 17.9305L12.3748 11.9722ZM6.41547 4.06826L13.9954 8.40709L12.3748 10.0277L6.41547 4.06826ZM15.232 12.8848L13.3471 10.9999L15.2321 9.11494L18.5251 10.9999L15.232 12.8848Z" fill="black"/>
                            </svg>
                         </span>
                      </div>
                   </div>
                   <p className="case-disp pb-3 ">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl ac donec nulla ut vel. Urna molestie..</p>
                   <button  onclick="location.href = 'single-case-study.html'; return false;" className="estimate-btn btn btn-bg btn-design text-white px-4 mb-4 ">
                   Read Case Study 
                   </button>
                </div>
             </div>
          </div>
       </div>
       <div className="web-bg">
          <svg width="489" height="212" viewBox="0 0 489 212" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M490 67.5004L366.13 17.6306L7.49989 116.5L490 141.5L490 67.5004Z" fill="#F4F8FF"></path>
          </svg>
       </div>
      </div>
    </section>
   )
}

export default SimilarCaseStudy;