import React from 'react';
import Layout from "../components/layout";
import Banner from "../components/casestudysingle/banner";
import Background from "../components/casestudysingle/background";
import Challenges from "../components/casestudysingle/challenges";
import Solution from "../components/casestudysingle/solution";
import SimilarCaseStudy from "../components/casestudysingle/similarcasestudy";
import Testimonial from "../components/home/testimonial";
import { graphql } from "gatsby"
import SEO from "../components/seo";

const CaseStudy = (props) =>{
    console.log("Data", props.data);
    return(
        <Layout>
                <SEO title="Case Study" />
                <div class="col-12 wrapper main-bggrey p-0">
                    <div class="col-12 inner-page-content home-inner px-0"> 
                        <Banner data={props.data}/>
                    </div>
                </div>
                <Background data={props.data}/>
                <Challenges data={props.data}/>
                <Solution data={props.data}/>
                <SimilarCaseStudy data={props.data}/>
                <Testimonial/>
        </Layout>  
    )
} 

export const caseStudyCategory = graphql`
  query($slug: String!) {
    allWpCaseStudy(filter: {slug: {eq: $slug}}) {
        edges {
          node {
            content
            title
            featuredImage {
              node {
                sourceUrl
                slug
              }
            }
            slug
            CaseStudyFields {
              socialShare {
                shareOn {
                  url
                  title
                  icon
                }
              }
              services {
                title
                websiteLink
                visitWebsite
                servicesList {
                  name
                  description
                }
              }
              background {
                description
                subTitle
                title
              }
              solution {
                description
                image1 {
                  sourceUrl
                }
                image2 {
                  sourceUrl
                }
                subTitle
                title
              }
              notableChallanges {
                description
                title
                subTitle
              }
            }
            caseStudyCategories {
              nodes {
                name
                description
                caseStudies {
                  nodes {
                    title
                    content
                    slug
                  }
                }
                slug
                parentId
              }
            }
          }
        }
      }
   }
`;

export default CaseStudy;