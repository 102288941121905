import React from 'react';
import {Link} from 'gatsby';

const Background = (props) => {
   return(
      <section className="share-sec">
      <div className="share-top single-case">
         <svg width="366" height="185" viewBox="0 0 366 185" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M51.8221 167.583L-35.3348 87.7328L-40 34.2603L358.287 64.1671L83.1531 173.347C72.4676 177.588 60.2986 175.349 51.8221 167.583Z" fill="#D8F4FF"/>
         </svg>
      </div>
      <div className="container mt-5 mb-1">
         <div className="col-md-10 mx-auto pt-5">
            <div className="row">
               {props.data && 
                props.data.allWpCaseStudy &&
                props.data.allWpCaseStudy.edges[0] &&
                props.data.allWpCaseStudy.edges[0].node &&
                <div className="col-md-2 share-title text-center">
                  SHARE
                  <ul className="mx-0 mt-4 p-0 social-links">
                     {props.data.allWpCaseStudy.edges[0].node
                     &&props.data.allWpCaseStudy.edges[0].node.CaseStudyFields
                     &&props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.socialShare
                     &&props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.socialShare.shareOn
                     &&props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.socialShare.shareOn.length>0
                     &&props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.socialShare.shareOn.map((share,key)=>{
                         return(
                           <Link key={key} to={share.url}>
                              <li dangerouslySetInnerHTML={{__html:share.icon}} key={key}></li>
                           </Link>
                         )
                     })
                    }
                  </ul>
               </div>}
               {props.data&&
               props.data.allWpCaseStudy &&
               props.data.allWpCaseStudy.edges[0] &&
               props.data.allWpCaseStudy.edges[0].node &&
               props.data.allWpCaseStudy.edges[0].node.CaseStudyFields &&
               props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.background &&
               <div className="col-md-10">
                  <div className="share-sub-heading">{props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.background.subTitle}</div>
                  <h2 className="share-main-title w-100">{props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.background.title}</h2>
                  <div dangerouslySetInnerHTML={{__html:props.data.allWpCaseStudy.edges[0].node.CaseStudyFields.background.description}}></div>
                 
               </div>}
            </div>
         </div>
      </div>
      <div className="share-bottom">
         <svg width="399" height="185" viewBox="0 0 399 185" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M84.8221 16.7452L-2.33479 96.5951L-7.00001 150.068L391.287 120.161L116.153 10.9806C105.468 6.74033 93.2986 8.97928 84.8221 16.7452Z" fill="#D8F4FF"/>
         </svg>
      </div>
   </section>
   )
}

export default Background;